<!-- 注册 -->
<template>
  <div class="register">
    <div class="header">
      <img src="../assets/logo2.png" class="logo" alt />
      <div class="dlzcan">
        <router-link to="/login"></router-link>
        <a href="/?#/account/register"></a>
      </div>
    </div>

    <el-tabs type="border-card" class="regbox">
      <el-tab-pane label="个人账号">
        <v-form ref="talentForm" v-model="talentValid" lazy-validation>
          <v-row class="row-height">
            <v-col cols="2" class="tittext">
              <small style="position:relative; top: 8px">用户账号</small>
            </v-col>
            <v-col cols="9" class="comtext">
              <v-text-field
                required
                :error-messages="errorTalent.username"
                v-model="talentForm.username"
                :rules="usernamesRules"
                dense
                outlined
                placeholder="请输入正确的用户账号，此为登录账号"
              />
            </v-col>
          </v-row>

          <v-row class="row-height">
            <v-col cols="2" class="tittext">
              <small style="position:relative; top: 8px">真实姓名</small>
            </v-col>
            <v-col cols="9" class="comtext">
              <v-text-field
                v-model="talentForm.name"
                required
                placeholder="请输入姓名"
                :rules="namesRules"
                dense
                outlined
                :error-messages="errorTalent.name"
              />
            </v-col>
          </v-row>

          <v-row class="row-height">
            <v-col cols="2" class="tittext">
              <small style="position:relative; top: 8px">手机号</small>
            </v-col>
            <v-col cols="9" class="comtext">
              <v-text-field
                required
                :error-messages="errorTalent.contact"
                v-model="talentForm.contact"
                :rules="cellRules"
                dense
                outlined
                placeholder="请输入手机号"
              />
            </v-col>
          </v-row>

          <v-row class="row-height">
            <v-col cols="2" class="tittext">
              <small style="position:relative; top: 8px">证件类型</small>
            </v-col>
            <v-col cols="9" class="comtext">
              <v-select
                dense
                outlined
                placeholder="请选择证件类型"
                v-model="talentForm.identityType"
                :items="documentType"
                :rules="identityTypeRules"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row class="row-height">
            <v-col cols="2" class="tittext">
              <small style="position:relative; top: 8px">证件编号</small>
            </v-col>
            <v-col cols="9" class="comtext">
              <v-text-field
                required
                :error-messages="errorTalent.identityCode"
                v-model="talentForm.identityCode"
                :rules="identityCodeRules"
                dense
                outlined
                placeholder="请输入证件编号"
              />
            </v-col>
            <div class="identityCode">* 身份证注册后将不可修改</div>
          </v-row>

          <v-row class="row-height">
            <v-col cols="2" class="tittext">
              <small style="position:relative; top: 8px">设置密码</small>
            </v-col>
            <v-col cols="9" class="comtext">
              <v-text-field
                required
                :error-messages="errorTalent.password"
                v-model="talentForm.password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                counter
                :rules="passwordRules"
                dense
                outlined
                placeholder="请输入密码，格式为：Aa#123"
              />
            </v-col>
          </v-row>

          <v-row class="row-height">
            <v-col cols="2" class="tittext">
              <small style="position:relative; top: 8px">验证码</small>
            </v-col>
            <v-col cols="5">
              <v-text-field
                required
                :error-messages="errorTalent.imageCode"
                v-model="talentForm.imageCode"
                :rules="imageIdRules"
                dense
                outlined
                placeholder="请输入图形验证码"
              />
            </v-col>
            <v-col cols="4">
              <div @click="requestImg">
                <!--suppress HtmlUnknownTarget -->
                <img :src="imageId" alt="快点击我" />
              </div>
            </v-col>
          </v-row>

          <v-row class="row-height">
            <v-col cols="2" class="tittext">
              <small style="position:relative; top: 8px">验证码</small>
            </v-col>
            <v-col cols="5">
              <v-text-field
                required
                :error-messages="errorTalent.smsCode"
                v-model="talentForm.smsCode"
                :rules="smsCodeeRules"
                dense
                outlined
                placeholder="请输入短信验证码"
              />
            </v-col>
            <v-col cols="4">
              <el-button
                type="mini"
                @click="requestCode('talent')"
                v-text="btntxt"
                :disabled="disabled"
                >获取验证码
              </el-button>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                type="primary"
                @click="talentValidate"
                style="width: 60%;background-color: #64B5F6;color: #E3F2FD"
                >提交注册
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </el-tab-pane>

      <el-tab-pane label="单位账号">
        <v-form ref="enterpriseForm" v-model="enterpriseValid" lazy-validation>
          <v-row class="row-height">
            <v-col cols="2" class="tittext">
              <small style="position:relative; top: 8px">单位名称</small>
            </v-col>
            <v-col cols="9" class="comtext">
              <v-text-field
                required
                :error-messages="errorEnterprise.name"
                v-model="enterpriseForm.name"
                :rules="usernameRules"
                dense
                outlined
                placeholder="请输入单位名称"
              />
            </v-col>
          </v-row>

          <v-row class="row-height">
            <v-col cols="2" class="tittext">
              <small style="position:relative; top: 8px">用户名</small>
            </v-col>
            <v-col cols="9" class="comtext">
              <v-text-field
                v-model="enterpriseForm.username"
                required
                :rules="nameRules"
                dense
                outlined
                :error-messages="errorEnterprise.username"
                placeholder="请输入用户名"
              />
            </v-col>
          </v-row>

          <v-row class="row-height">
            <v-col cols="2" class="tittext">
              <small style="position:relative; top: 8px">手机号</small>
            </v-col>
            <v-col cols="9" class="comtext">
              <v-text-field
                required
                :error-messages="errorEnterprise.contact"
                v-model="enterpriseForm.contact"
                :rules="cellRules"
                dense
                outlined
                placeholder="请输入手机号"
              />
            </v-col>
          </v-row>

          <v-row class="row-height">
            <v-col cols="2" class="tittext">
              <small style="position:relative; top: 4px"
                >统一社会信用代码</small
              >
            </v-col>
            <v-col cols="9" class="comtext">
              <v-text-field
                required
                :error-messages="errorEnterprise.socialCreditCode"
                v-model="enterpriseForm.socialCreditCode"
                :rules="socialCreditCodeRules"
                dense
                outlined
                placeholder="请输入统一社会信用代码"
              />
            </v-col>
          </v-row>

          <v-row class="row-height">
            <v-col cols="2" class="tittext">
              <small style="position:relative; top: 8px">设置密码</small>
            </v-col>
            <v-col cols="9" class="comtext">
              <v-text-field
                required
                :error-messages="errorEnterprise.password"
                v-model="enterpriseForm.password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                counter
                :rules="passwordRules"
                dense
                outlined
                placeholder="请输入密码，格式为：Aa#123"
              />
            </v-col>
          </v-row>

          <v-row class="row-height">
            <v-col cols="2" class="tittext">
              <small style="position:relative; top: 8px">验证码</small>
            </v-col>
            <v-col cols="5">
              <v-text-field
                required
                :error-messages="errorEnterprise.imageCode"
                v-model="enterpriseForm.imageCode"
                :rules="imageIdRules"
                dense
                outlined
                placeholder="请输入图形验证码"
              />
            </v-col>
            <v-col cols="4">
              <div @click="requestImg">
                <!--suppress HtmlUnknownTarget -->
                <img :src="imageId" alt="快点击我" />
              </div>
            </v-col>
          </v-row>
          <v-row class="row-height">
            <v-col cols="2" class="tittext">
              <small style="position:relative; top: 8px">验证码</small>
            </v-col>
            <v-col cols="5">
              <v-text-field
                required
                :error-messages="errorEnterprise.smsCode"
                v-model="enterpriseForm.smsCode"
                :rules="smsCodeeRules"
                dense
                outlined
                placeholder="请输入短信验证码"
              />
            </v-col>
            <v-col cols="4">
              <el-button
                type="mini"
                @click="requestCode('enterprise')"
                v-text="btntxt"
                :disabled="disabled"
                >获取验证码
              </el-button>
            </v-col>
          </v-row>

          <v-row class="row-height">
            <v-col>
              <v-btn
                type="primary"
                :disabled="enterpriseDisabled"
                @click="enterpriseValidate"
                style="width: 60%;background-color: #64B5F6;color: #E3F2FD"
                >提交注册
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </el-tab-pane>
    </el-tabs>
    <img src="../assets/Tips.jpg" class="tips" alt />
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/WebPanel/footer.vue";

export default {
  components: {
    Footer,
  },
  data() {
    return {
      show: "",
      talentValid: false,
      enterpriseValid: true,
      name: "",
      talentDisabled: false,
      enterpriseDisabled: false,
      rotate: "",
      time: 60,
      disabled: false,
      btntxt: "获取验证码",
      documentType: [
        "身份证",
        "普通护照",
        "港澳居民来内地通行证",
        "台湾居民来内地通行证",
        "外国人永久居留证",
      ],
      talentForm: {
        identityCode: "",
        identityType: "",
        contact: "",
        name: "",
        password: "",
        accountStatus: 1,
        imageCode: "",
        imageId: "",
        smsId: "",
        smsCode: "",
        username: "",
      },
      errorTalent: {
        identityCode: "",
        contact: "",
        name: "",
        password: "",
        accountStatus: 1,
        imageCode: "",
        imageId: "",
        smsId: "",
        smsCode: "",
        username: "",
      },
      enterpriseForm: {
        name: "",
        contact: "",
        password: "",
        socialCreditCode: "",
        imageId: "",
        imageCode: "",
        //暂时注释
        smsId: "",
        smsCode: "",
        username: "",
      },
      errorEnterprise: {
        name: "",
        contact: "",
        password: "",
        socialCreditCode: "",
        imageId: "",
        imageCode: "",
        //暂时注释
        smsId: "",
        smsCode: "",
        username: "",
      },
      imageId: "",
      codeNumber: "",
      //  个人
      usernamesRules: [
        // v => !!v || '请输入正确的用户账号，此为登录账号，可使用邮箱等名称，长度在 5 到 30 个字符',
        (v) =>
          !!v || "请输入正确的用户账号，此为登录账号，长度在 5 到 30 个字符",
        // v => ((/^[a-zA-Z\u4e00-\u9fa5]+$/.test(v) && v.length <= 30 && v.length >= 5) || (/.+@.+\..+/.test(v) && v.length <= 30 && v.length >= 5)) || '全字母或使用邮箱账号，长度在 5 到 30 个字符',
        (v) =>
          (/^[a-zA-Z\u4e00-\u9fa5]+$/.test(v) &&
            v.length <= 30 &&
            v.length >= 5) ||
          (/.+@.+\..+/.test(v) && v.length <= 30 && v.length >= 5) ||
          "全字母账号，长度在 5 到 30 个字符",
      ],
      namesRules: [
        (v) => !!v || "请输入真实的姓名",
        (v) =>
          (/^[a-zA-Z\u4e00-\u9fa5]+$/.test(v) &&
            v.length <= 10 &&
            v.length >= 2) ||
          "真实名只能为中文和字母,且长度在 2 到 10 个字符",
      ],
      passwordRules: [
        (v) => !!v || "5-20位包含字母大、小写、数字与特殊字符，例Rc#123",
        (v) =>
          /^(?=.*\d)(?=.*[!,@,#,$,%,^,&,*,.,~])(?=.*[a-z])(?=.*[A-Z]).{5,20}/.test(
            v
          ) || "5-20位包含字母大、小写、数字与特殊字符，例Rc#123",
        (v) =>
          (v && v.length >= 5 && v.length <= 20) || "长度在 5 到 20 个字符",
      ],
      cellRules: [
        (v) => !!v || "请输入手机号",
        (v) => /^1[3456789]\d{9}$/.test(v) || "请输入正确的手机号",
      ],
      identityTypeRules: [(v) => !!v || "请选择证件类型"],
      identityCodeRules: [
        (v) => !!v || "请输入证件编号",
        (v) => (v && v.length <= 25) || "请输入正确的证件编号",
      ],
      imageIdRules: [
        (v) => !!v || "请输入图形验证码",
        (v) => (v && v.length === 4) || "长度为4位",
      ],
      smsCodeeRules: [
        (v) => !!v || "请输入短信验证码",
        (v) => (/^[0-9]*$/.test(v) && v.length === 6) || "请填写数字且为六位",
      ],
      //  单位
      usernameRules: [
        (v) => !!v || "请输入单位名称",
        (v) => (v && v.length <= 30) || "单位名称长度为30之内",
        /* v => /^[a-zA-Z\u4e00-\u9fa5]+$/.test(v) || '单位名称为全汉字或全字母', */
      ],
      nameRules: [
        (v) => !!v || "请输入用户名",
        (v) =>
          (/^[A-Za-z]+$/.test(v) && v.length >= 5 && v.length <= 30) ||
          (/.+@.+\..+/.test(v) && v.length <= 30 && v.length >= 5) ||
          "全字母账号，长度在 5 到 30 个字符",
      ],
      socialCreditCodeRules: [
        (v) => !!v || "请输入统一社会信用代码",
        (v) => (v && v.length === 18) || "单位社会统一信用代码位18位",
      ],
    };
  },

  created() {
    this.requestImg();
    //this.timer()
  },
  methods: {
    talentValidate() {
      //   this.talentForm.smsId = 1
      if (this.$refs.talentForm.validate()) {
        let self = this;
        this.$axios.post("/talent", this.talentForm).then(function(response) {
          console.log(response);
          if (response.data.status === 200) {
            self.$message({
              message: "恭喜你，注册成功",
              type: "success",
            });
            self.$router.push("/login?name=second");
          } else {
            self.talentForm.imageCode = "";
            self.requestImg();
            self.errorTalent = response.data.data;

            if (typeof response.data.data == "string") {
              self.$message({
                message: response.data.data,
                type: "warning",
              });
            }
          }
        });
      } else {
        return false;
      }
    },

    enterpriseValidate() {
      if (this.$refs.enterpriseForm.validate()) {
        let self = this;
        this.$axios
          .post("/enterprise", {
            accountStatus: 1,
            name: self.enterpriseForm.name,
            contact: self.enterpriseForm.contact,
            password: self.enterpriseForm.password,
            socialCreditCode: self.enterpriseForm.socialCreditCode,
            imageId: self.enterpriseForm.imageId,
            imageCode: self.enterpriseForm.imageCode,
            smsId: self.enterpriseForm.smsId || 1,
            smsCode: self.enterpriseForm.smsCode,
            username: self.enterpriseForm.username,
          })
          .then(function(response) {
            if (response.data.status === 200) {
              self.$message({
                message: "恭喜你，注册成功",
                type: "success",
              });
              self.$router.push("/login?name=second");
            } else {
              self.enterpriseForm.imageCode = "";
              self.requestImg();
              self.errorEnterprise = response.data.data;
              if (typeof response.data.data == "string") {
                self.$message({
                  message: response.data.data,
                  type: "warning",
                });
              }
            }
          });
      } else {
        return false;
      }
    },

    //  图形验证码
    requestImg() {
      this.$axios.get("/verify/image").then((response) => {
        this.imageId = response.data.data.image;
        this.talentForm.imageId = response.data.data.id;
        this.enterpriseForm.imageId = response.data.data.id;

        // this.talentForm.imageVerifyId = response.data.data.id;
        // this.enterpriseForm.imageVerifyId = response.data.data.id;
      });
    },

    //  短信倒计时
    timer() {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.btntxt = this.time + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.disabled = false;
      }
    },

    //  校验手机号是否存在
    requestCode(type) {
      if (type === "talent") {
        if (this.talentForm.contact.length === 0) {
          this.disabled = true;
          setTimeout(() => {
            this.disabled = false;
          }, 3000);
          this.$root.warn("请先输入手机号");
          return false;
        }
      } else if (type === "enterprise") {
        if (this.enterpriseForm.contact.length === 0) {
          this.disabled = true;
          setTimeout(() => {
            this.disabled = false;
          }, 3000);
          this.$root.warn("请先输入手机号");
          return false;
        }
      }
      let self = this;
      if (type === "talent") {
        this.$axios
          .get("/common/check/phone", {
            params: {
              phone: this.talentForm.contact,
            },
          })
          .then(function(res) {
            if (res.data.data) {
              self.$message({
                message: "手机号已被注册",
                type: "warning",
              });
              self.requestImg();
              self.talentForm.imageCode = "";
            } else {
              self.getsms(type);
            }
          });
      } else {
        this.$axios
          .get("/common/check/phone", {
            params: {
              phone: this.enterpriseForm.contact,
            },
          })
          .then(function(res) {
            if (res.data.data) {
              self.$message({
                message: "手机号已被注册",
                type: "warning",
              });
              self.requestImg();
              self.enterpriseForm.imageCode = "";
            } else {
              self.getsms(type);
            }
          });
      }
    },

    //  获取手机验证码
    getsms(type) {
      let talentdata = {
        imageVerifyCode: this.talentForm.imageCode,
        imageVerifyId: this.talentForm.imageId,
        phone: this.talentForm.contact,
      };
      let enterprisedata = {
        imageVerifyCode: this.enterpriseForm.imageCode,
        imageVerifyId: this.enterpriseForm.imageId,
        phone: this.enterpriseForm.contact,
      };
      if (type === "talent") {
        if (this.talentForm.imageCode.length === 0) {
          this.disabled = true;
          setTimeout(() => {
            this.disabled = false;
          }, 3000);
          this.$root.warn("请先输入图形码");
          return false;
        }
      } else if (type === "enterprise") {
        if (this.enterpriseForm.imageCode.length === 0) {
          this.disabled = true;
          setTimeout(() => {
            this.disabled = false;
          }, 3000);
          this.$root.warn("请先输入图形码");
          return false;
        }
      }
      this.$axios
        .get("/common/verify/sms", {
          params: type == "talent" ? talentdata : enterprisedata,
        })
        .then((response) => {
          if (response.data.status === 200) {
            if (type === "talent") {
              this.time = 60;
              this.timer();
              this.talentForm.smsId = response.data.data;
            } else {
              this.time = 60;
              this.timer();
              this.enterpriseForm.smsId = response.data.data;
            }
          } else {
            if (response.data.status === 500) {
              if (type === "talent") {
                this.$root.warn("验证码发送失败");
                this.requestImg();
                this.talentForm.imageCode = "";
              } else if (type === "enterprise") {
                this.$root.warn("验证码发送失败");
                this.requestImg();
                this.enterpriseForm.imageCode = "";
              }
            } else {
              if (type === "talent") {
                if (response.data.data === "图形验证码已过期") {
                  this.$root.warn(response.data.data);
                  this.requestImg();
                  this.talentForm.imageCode = "";
                } else if (response.data.data === "图形验证码错误") {
                  this.$root.warn(response.data.data);
                  this.requestImg();
                  this.talentForm.imageCode = "";
                } else {
                  this.$root.warn(response.data.data);
                }
              } else if (type === "enterprise") {
                if (response.data.data === "图形验证码已过期") {
                  this.$root.warn(response.data.data);
                  this.requestImg();
                  this.enterpriseForm.imageCode = "";
                } else if (response.data.data === "图形验证码错误") {
                  this.$root.warn(response.data.data);
                  this.requestImg();
                  this.enterpriseForm.imageCode = "";
                } else {
                  this.$root.warn(response.data.data);
                }
              } else {
                this.$root.warn("验证码发送失败");
              }
            }
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.row-height {
  height: 72px;
}

.tips {
  height: 14%;
  margin-left: 65%;
  margin-top: -36px;
}

.register {
  height: 100vh;
  min-height: 950px;
  background: url("../assets/register.jpg") no-repeat top center;
  background-size: cover;

  .header {
    width: 1200px;
    margin: 0 auto;
    height: 20vh;
    position: relative;

    .logo {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -27.5px;
    }

    .dlzcan {
      position: absolute;
      right: 0;
      top: 50%;
      width: 231px;
      height: 53px;
      margin-top: -26.5px;
      background: url("../assets/dlzcan.png") no-repeat top center;

      a {
        height: 53px;
        width: 50%;
        display: block;
        float: left;
      }
    }
  }

  .regbox {
    width: 35%;
    min-width: 540px;
    position: absolute;
    z-index: 9;
    left: 45%;
    top: 45%;
    margin-top: -304px;
    margin-left: -240px;

    .reg-form {
      padding: 0 20px 0 0;

      .yzmbut {
        position: absolute;
        top: 7px;
        right: 5px;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100vw;
    font-size: 14px;
  }
}

.Transition {
  transition: all 1s;
}

.go {
  transform: rotate(360deg);
  transition: all 1s;
}

.img {
  width: 32px;
  height: 32px;
  margin-left: 4px;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.identityCode {
  font-size: 12px;
  position: absolute;
  right: 11%;
  top: 356px;
  color: red;
}
@import "../styles/common_responsive.less";
</style>
